module.exports = {
  lang: {
    Confirmation: '确认函',
    company: '公司',
    regist_tip0: '线上研讨会注册',
    regist_tip1: '点击此处直接观看',
    Next: '下一步',
    Name: '姓名',
    FullName: '真实姓名',
    Tipslogin: 'ams-live平台仅对注册用户开放',
    PleaseMobileLogin: '请使用手机号登录',
    PleaseEmailLogin: '请使用邮箱登录',
    Position: '职位',
    Country: '国家/地区',
    Questionnaire: '问卷',
    Colleague: '同事',
    MarkIsRequired: '标注 * 是必填项',
    MobileCode: '国家/地区代码',
    Province: '省份',
    City: '城市',
    Street: '街道',
    website: '官方网站',
    Telephone: '电话号码',
    AreaCode: '区号',
    CountryCode: '国家代码',
    CountryRegionCode: '国家/地区代码',
    FillInAgain: '重新填写',
    PleaseEnterMobileNumber: '请输入手机号码',
    PleaseEnterMobileCode: '请输入短信验证码',
    PleaseEnterEmailCode: '请输入邮箱验证码',
    PleaseEnterCompanyName: '请输入公司名称',
    PleaseEnterPosition: '请输入职位',
    Pleaseenteremail2: '请输入邮箱',
    PleaseEnterTheCorrectEmailAddress: '请输入正确的邮箱地址',
    PersonalInformation: '个人信息',
    PleaseEnterDepartment: '请输入部门',
    PleaseSelectCountryOrRegion: '请选择一个国家或地区',
    PleaseSelectProvince: '请选择一个省份',
    PleaseEnterCountryCode: '请输入国家/地区代码',
    TheCountryCodeIsNumericValue: '国家/地区代码为纯数字',
    PleaseEnterTheAreaCode: '请输入区号',
    AreaCodeIsNumericValue: '区号为纯数字',
    PleaseEnterThePhone: '请输入手机号',
    PhoneIsNumericValue: '手机号是纯数字',
    PleaseChooseOne: '请选择一项',
    PleaseSelectMultiple: '请多选',
    PleaseVerificationCode: '请输入验证码',
    PleaseEnterNumber: '格式错误，请输入数字',
    PleaseEnterNumber2: '格式错误，请输入至少5位的数字',
    RecommendColleague: '推荐同事',
    Recommend: '邀请同事',
    PhoneNumberAlreadyExists: '该手机号已存在',
    EmailAlreadyExists: '该邮箱已存在',
    PhoneWillBeYourLoginAccount: '手机号将作为您的登录账号',
    EmailWillBeYourLoginAccount: '邮箱将作为您的登录账号',
    AuthCodeENTips: '如果您没有收到验证码邮件，请检查您的垃圾邮箱。 验证码有效期为30分钟',


    // new
    Username: '用户',
    Password: '登录密码',
    NewPassword: '确认密码',
    Email: '电子邮箱',
    Department: '称谓',
    LastName: '姓氏 ',
    FirstName: '名字',
    Company: '公司名称',
    Position: '职位',
    Mobilenumber: '移动电话',
    MobileTips: '(depends on whether registrants need to receive verify SMS to register)',

    // 补全信息提示
    memberTip: '您的信息不全，请前往补全并完成注册！',
    noRealNameTip: '您尚未实名认证，请前往认证并完成注册！',
    paymentTip: '您尚未完成支付，请前往继续支付！',
    questionTip: '您尚未完成问卷，请前往填写',
    errcode301: '账号不存在，请前往完成注册！',
    errcode302: '您的信息不全，请前往补全！',
    errcode303: '请前往登录！',
    errcode304: '账户错误！',
    errcode305: '用户错误！',
    errcode306: '请联系管理员！',
    goTo: '前往',

    //
    Pay: '支付',
    ContinueInvite: '继续邀请',
    InviteColleagues: '邀请同事',
    Submit: '提交',
    verificationCode: '验证码',
    verificationCodePhone: '手机验证码',
    verificationCodeEmail: '邮箱验证码',
    ClickToGetVerificationCode: '点击获取验证码',
    VerificationSuccessful: '验证成功',
    VerificationFailed: '验证失败',
    Verifying: '验证中...',
    Reacquire: '重新获取',
    GetError: '获取出错',
    PleaseEnter: '请输入',
    PleaseEnter: '请输入',
    VerificationCodeError: '验证码错误',
    SubmissionFailed: '提交失败',
    CertificateType: '证件类型',
    CertificateNumber: '证件号码',
    Certification: '实名认证',
    AccountCreatedSuccessfully: '账户创建成功',
    AccountCreationFailed: '账户创建失败',
    RecommendationSuccessfully: '推荐成功',
    RecommendationFailed: '推荐失败',
    PleaseChoosePaymentMethod: '请选择付款方式',
    PleaseSelectThePaymentOrder: '请选择付款订单',
    PleaseSearchForProducts: '请搜索展品',
    PleaseUseAnotherBrowserToOpen: '请使用其他浏览器打开',
    LanguageError: '语言信息不匹配',
    Friend: '朋友',
    Update: '修改',
    PleaseSelect: '请选择',
    ClickTheButtonBelowToTurnOnSmartVerification: '点击下面的按钮开启智能验证',
    wrongFormat: '格式错误',
    questionErrorTip: '您有未完成的问卷！',
    AgainLogin: '您的信息已过期，请登录',
    ageTip: '不接受18岁以下未成年人注册',

    // 
    // moblieLogin: '手机验证码登录',
    // emailLogin: '邮箱验证码登录',
    moblieLogin: '手机登录',
    emailLogin: '邮箱登录',
    AccountLogin: '账号登录',
    enterMoblie: '手机号',
    enterEmail: '展商账号/手机号/邮箱',
    enterEmail1: '邮箱',
    ForgotPassword: '忘记密码',
    Registered: '注册',
    LoginTips1: ' 第一次登入用户请先使用手机或邮箱登录',
    LoginTips2: '也给微信观众登记用户第一次登入使用',
    LoginTips3: '也给官网观众登记用户第一次登入使用 ',

    ForgotPasswordTitle: '请根据您注册时的手机或邮箱收到的验证码，更改密码',
    phoneBack: '手机验证码找回',
    emailBack: '邮箱验证码找回',
    moblie: '手机',

    VerificationCode: '验证码',
    SMSVerificationCode: '短信验证码',
    EmailVerificationCode: '邮箱验证码',
    Send: '发送',
    PleaseEnterVerificationCode: '如果没有在您的邮箱内收到验证码，请留意垃圾邮箱，验证码30分钟内有效。',
    PleaseEnterVerificationSMSCode: '请输入手机验证码',
    // NewPassword: '新密码',
    Reenterpassword: '重新输入密码',
    Pleaseenteremail: '请输入账号',
    PleaseEnterTheCorrectEmailAddress: '请输入正确的邮箱地址',
    Pleaseentermoblie: '请输入手机号',
    Pleaseenterpassword: '请输入密码',
    PleaseEnterThePasswordAgain: '请再次输入密码',
    Thepasswordsentereddoesnotmatchthefirstone: '输入的密码于第一次输入不匹配!',
    passwordTip: '您的密码必须包含:',
    to620characters: '6-20个字符',
    DonotuseSpacesorChinese: '不要含有空格或中文字符',
    Containsatleast2ofthefollowing: '包含至少2个类型：数字、字母或特殊字符',
    SignIn: '登录',
    CongratulationsPasswordchangedsuccessfully: '<div class="font24 AvenirLTStd_Medium">恭喜您!</div> 密码更改已成功.',
    goSign: '登录',

    Register: '注册',
    RegisterError: '账号已存在，请前往登录',
    reigstTips: '提示：此注册用于渔博会和渔商荟平台共同使用',
    inviteTip1: '我们诚挚邀请您以及您身边的同事和朋友进行预登记',
    inviteTip2: '邀请方式',
    inviteTip3: '<span>①</span> <p>点击下方“<strong>邀请同事</strong>”，提前为同行者完成预登记，节省现场办理时间。</p>',
    inviteTip4: '<span>②</span> <p>每次可邀请多位同行者，完成预登记及网上支付后，持有效确认码到现场直接进行胸卡打印。</p>',

    payTips: '距离二维码过期还有',
    payTips1: '秒！',
    payTips0: '包含未实名认证的不可全选！',




    showConfirmation: '查看确认函',
    error104108: '账户已存在',
    error107: '验证码错误',
    error114: '编辑会员失败',
    error115: '信息不匹配',
    error116: '注册失败',
    error500: '系统错误',
    error121: '支付失败',
    error123: '姓名不匹配',
    error122: '您账号已存在，请更换登录方式',
    error124: '请联系主办方，协助您完成本次注册',

    error304: '创建账户错误',
    error305306: '创建用户错误',
    error307: '登录错误',

    vorRegisterAccountSuccess: '账号创建成功，请前往登录',
  }
}