module.exports = {
  lang: {
    Confirmation: 'Confirmation',
    regist_tip0: 'Register to Webinar',
    company: 'Company',
    regist_tip1: 'Click here to watch now',
    Next: 'Next',
    Name: 'Name',
    FullName: 'Full Name',
    Tipslogin: 'ams-live is open to registered users only',
    PleaseMobileLogin: 'Please use your mobile phone number to log in',
    PleaseEmailLogin: 'Please use your email to log in',


    Email: 'Email',
    MobileCode: 'Area Code',
    signErrorTip: 'Account or password is error',

    Position: 'Position',
    Country: 'Country/Region',
    Questionnaire: 'Questionnaire',
    Colleague: 'Invite/Pay',
    MarkIsRequired: ' * Required Field',
    Department: 'Prefix',
    Province: 'State/Province',
    City: 'City',
    Street: 'Street',
    website: 'Website',
    Telephone: 'Telephone Number',
    AreaCode: 'Area code',
    CountryCode: '+',
    CountryRegionCode: 'Country/Region Code',
    FillInAgain: 'Reset Form',
    PleaseEnterFirstName: 'Please enter First Name',
    PleaseEnterLastName: 'Please enter Last Name',
    PleaseEnterMobileNumber: 'Please enter Mobile Number',
    PleaseEnterMobileCode: 'Please enter SMS verification code',
    PleaseEnterEmailCode: 'Please enter email verification code',
    PleaseEnterCompanyName: 'Please enter Company Name',
    PleaseEnterPosition: 'Please enter Position',
    Pleaseenteremail2: 'Please enter email',
    PleaseEnterTheCorrectEmailAddress: 'Please Enter The Correct Email Address',
    PersonalInformation: 'Personal Info',
    PleaseEnterDepartment: 'Please enter department',
    PleaseSelectCountryOrRegion: 'Please select a country or region',
    PleaseSelectProvince: 'Please select province',
    PleaseSelectCity: 'Please select city',
    PleaseEnterCountryCode: 'Please enter country code',
    TheCountryCodeIsNumericValue: 'The country code is a numeric value',
    PleaseEnterTheAreaCode: 'Please enter the area code',
    AreaCodeIsNumericValue: 'Area code is a numeric value',
    PleaseEnterThePhone: 'Please enter the phone',
    PhoneIsNumericValue: 'Phone is a numeric value',
    PleaseChooseOne: 'Please choose one',
    PleaseSelectMultiple: 'Please select multiple',
    pleaseEnterYourName: 'please enter your name',
    PleaseVerificationCode: 'please enter verification code',
    RecommendColleague: 'Recommend colleague',
    PleaseEnterNumber: 'Format error, please enter a number',
    PleaseEnterNumber2: 'Format error, please enter at least 5 digits',
    Recommend: 'Invite colleague/Friend',
    PhoneWillBeYourLoginAccount: 'Phone will be your login account',
    EmailWillBeYourLoginAccount: 'Email will be your login account',
    AuthCodeENTips: 'Please check your junk mailbox as well if you do not receive the verification code email. The verification code is valid for 30mins ',

    // new
    Username: 'Username',
    Password: 'Password',
    Email: 'Email',
    Department: 'Prefix',
    FirstName: 'First Name',
    LastName: 'Last Name',
    Company: 'Company Name',
    Position: 'Job Position',
    Mobilenumber: 'Mobile',
    MobileTips: '(depends on whether registrants need to receive verify SMS to register)',

    // 
    Pay: 'Pay',
    ContinueInvite: 'Continue invite',
    InviteColleagues: 'Invite colleagues',
    Submit: 'Submit',
    verificationCode: 'verification code',
    verificationCodePhone: 'moblie verification code',
    verificationCodeEmail: 'Email verification code',
    Reacquire: ' Resend',
    GetError: 'get error',
    PleaseEnter: 'Please enter',
    VerificationCodeError: 'Verification code error',
    SubmissionFailed: 'Submission Failed',
    CertificateType: 'ID Type',
    CertificateNumber: 'ID Number',
    Certification: 'Identification',
    AccountCreatedSuccessfully: 'Account created successfully',
    AccountCreationFailed: 'Account creation failed',
    RecommendationSuccessfully: 'Recommendation successfully',
    RecommendationFailed: 'Recommendation failed',
    PleaseChoosePaymentMethod: 'Please choose a payment method',
    PleaseSelectThePaymentOrder: 'Please select the payment order',
    ClickTheButtonBelowToTurnOnSmartVerification: 'Click the button below to turn on smart verification',
    ClickToGetVerificationCode: 'Click to get verification code',
    VerificationSuccessful: 'Verification successful',
    VerificationFailed: 'Verification failed',
    Verifying: 'Verifying...',
    wrongFormat: 'wrong format',
    PleaseSearchForProducts: 'Please search for products',
    PleaseUseAnotherBrowserToOpen: 'Please use another browser to open',
    PleaseSelect: 'Please select',
    LanguageError: 'Language information does not match',
    Friend: 'Friend',
    Update: 'Update',
    questionErrorTip: 'You have uncompleted questionnaires!',
    ageTip: '不接受18岁以下未成年人注册',
    memberTip: 'Your information is incomplete, please sign in. ',
    noRealNameTip: 'Your ID information is not sufficient, please go to Identification page and complete registration!',
    paymentTip: 'You have not completed the payment, please go to the payment!',
    questionTip: 'Your information is incomplete, please sign in',
    goTo: 'Go to',

    AgainLogin: 'Your information has expired, please log in',

    //
    moblieLogin: 'Mobile Login',
    emailLogin: 'E-mail Login',
    AccountLogin: 'Account Login',
    enterMoblie: 'Mobile Number',
    enterEmail: 'Exhibitor account/Email/Mobile',
    enterEmail1: 'Email',
    Password: 'Password',
    ForgotPassword: 'Forgot Password',
    Registered: 'Register',
    LoginTips1: 'For first-time users please use E-mail Login or Mobile Login',
    LoginTips2: 'Also for first-time Wechat registered visitors',
    LoginTips3: 'Also for first-time website registered visitors',

    ForgotPasswordTitle: 'Please input your Email when you register, and change your password according to the verification code',
    phoneBack: 'Mobile verification code recovery',
    emailBack: 'Email verification code recovery',
    moblie: 'moblie',

    VerificationCode: 'Verification Code',
    SMSVerificationCode: 'SMS verification code',
    EmailVerificationCode: 'Email verification code',
    Send: 'Send',
    PleaseEnterVerificationCode: 'Please check your junk mailbox as well if you do not receive the verification code email. The verification code is valid for 30mins',
    PleaseEnterVerificationSMSCode: 'Please input mobile phone verification code',
    NewPassword: 'Re-enter password',
    Reenterpassword: 'Re-enter password',
    Pleaseenteremail: 'Please enter account',
    PleaseEnterTheCorrectEmailAddress: 'Please Enter The Correct Email Address',
    Pleaseentermoblie: 'Please enter Moblie',
    Pleaseenterpassword: 'Please enter Password',
    PleaseEnterThePasswordAgain: 'Please Enter The Password Again',
    Thepasswordsentereddoesnotmatchthefirstone: 'The passwords entered does not match the first one!',
    passwordTip: 'Your password must contain:',
    to620characters: '6 to 20 characters',
    DonotuseSpacesorChinese: 'Do not use Spaces or Chinese',
    Containsatleast2ofthefollowing: 'Contains at least 2 of the following types: numbers, letters, and special characters.',
    SignIn: 'Sign In',
    CongratulationsPasswordchangedsuccessfully: '<div class="font24 AvenirLTStd_Medium">Congratulations!</div> Password changed successfully.',
    goSign: 'Sign In',

    Register: 'Register',
    RegisterError: 'Your account has already existed, please login',
    reigstTips: "Note: This registration is for entry into China Fisheries & Seafood Expo and online access to the CFSE Global Marketplace.",

    inviteTip1: 'We cordially invite you and your colleagues and friends to pre-register for China Fisheries & Seafood Expo 2021 and CFSE-Global Marketplace',
    inviteTip2: ' Invitation mode ',
    inviteTip3: '<span>①</span> <p>Click “Invite Colleague/Friend“ below, and complete pre-registration for your colleagues or friends, saving their on-site registration time.</p>',
    inviteTip4: '<span>②</span> <p>Multiple people can be registered each time. After completing pre-registration and online payment, you will receive confirmation email with registration codes, which can be used to print the badge on site with a valid personal ID.</p>',

    payTips: 'How far is it from the expiration of the two-dimensional code',
    payTips1: 'Seconds',
    payTips0: "You can't select all of them without real name authentication!",
    showConfirmation: 'Confirmation',
    error104108: 'Account already exists',
    error107: 'Verification code error',
    error114: 'Edit membership failed',
    error115: 'Information mismatch',
    error116: 'Registration failed',
    error500: 'System error',
    error121: 'Payment error',
    error123: 'Name does not match',
    error122: 'Your account already exists, please change the login method',
    error124: 'Please contact the organizer to help you with the further registation',

    errcode301: 'The account does not exist, please go to complete the registration!',
    errcode302: 'Your information is incomplete, please go to complete it!',
    errcode303: 'Please login!',
    errcode304: 'Account error!',
    errcode305: 'User error!',
    errcode306: 'Please contact the administrator!',

    vorRegisterAccountSuccess: 'The account is created successfully, please login',
  }
}