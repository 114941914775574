import Cookies from 'js-cookie'
// import config from '../config.js'

const version='20190505';

export function setCookie(name, value) {
  return Cookies.set(name, value)
}

export function getCookie(name) {
  return Cookies.get(name)
}

export function removeCookie(name) {
  return Cookies.remove(name)
}

export function setShowCookie(name, value) {
  let showdetailsid = getCookie("showdetailsid")||getCookie("exh");
  return Cookies.set(showdetailsid+'_'+name, value)
}

export function getShowCookie(name) {
  let showdetailsid = getCookie("showdetailsid")||getCookie("exh");
  return Cookies.get(showdetailsid+'_'+name)
}

export function removeShowCookie(name) {
  let showdetailsid = getCookie("showdetailsid")||getCookie("exh");
  return Cookies.remove(showdetailsid+'_'+name)
}

export function getOpenid() {
  return getShowCookie('openid');
}

export function setOpenid(openid) {
  return setShowCookie('openid', openid)
}

export function removeOpenid() {
  return removeShowCookie('openid')
}

export function getUserdef16() {
  return getShowCookie('userdef16');
}

export function setUserdef16(userdef16) {
  return setShowCookie('userdef16', userdef16)
}

export function removeUserdef16() {
  return removeShowCookie('userdef16')
}

export function getToken() {
  return getShowCookie('token');
}

export function setToken(token) {
  return setShowCookie('token', token)
}

export function removeToken() {
  return removeShowCookie('token')
}

export function setLang() {
  return removeShowCookie('lang')
}

export function setChianFishToken( tokenName,token) {
  localStorage.setItem(tokenName,encodeURIComponent(token))
}
export function getChinaFishToken(tokenName) {
  return decodeURIComponent(localStorage.getItem(tokenName))
}
export function delChinaFishToken(tokenName) {
  localStorage.removeItem(tokenName)
}
