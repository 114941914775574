import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getCookie } from "@/utils/cookieinfo.js";

Vue.use(VueI18n)

let locale = getCookie('lang');
const i18n = new VueI18n({
  locale: 'zh',
  messages: {
     'zh': require('./zh'),
     'en': require('./en')
  }
})

export default i18n