import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import { Dialog, Toast  } from 'vant';
import router from '@/router'  //引入router
import qs from 'qs'

// 创建axios实例
const service = axios.create({
  baseURL: "https://openapi.wemeet.com/api", // api 的 base_url
  timeout: 60000 // 请求超时时间
})

// request 拦截
service.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      config.data = qs.stringify(config.data);
    }
    return config
  },
  error => {
    return error
  }
);

// response 拦截
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    return error.data
  }
);

export default service
