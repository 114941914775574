import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  setCookie,
  getCookie,
} from '@/utils/cookieinfo'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/login.vue')
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  let lang = getCookie('lang') || 1;
  if (to.query.lan) {
    lang = to.query.lan.toLowerCase() == 'en' ? 2 : 1;
    setCookie('lang', 1)
  }
  next()
})

export default router
